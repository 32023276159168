<template>
  <div class="home" :style="{ background: !load ? '#fff' : 'none' }">
    <div
      v-if="activityDetails.check_status === 1 && activityDetails.status === 1"
    >
      <div style="position: relative; width: 100%; font-size: 0">
        <img
          v-if="activityDetails.image_type === 'image'"
          style="width: 100%"
          :src="
            activityDetails.images
              ? activityDetails.images[0]
              : '../assets/banner.png'
          "
          alt=""
        />
        <el-carousel
          v-else-if="activityDetails.image_type === 'swiper'"
          trigger="click"
          height="22.4rem"
          width="100%"
        >
          <el-carousel-item
            v-for="(item, index) in activityDetails.images"
            :key="index"
          >
            <img :src="item" alt="" style="width: 100%" />
          </el-carousel-item>
        </el-carousel>
        <video
          v-else-if="
            activityDetails.image_type === 'video' && activityDetails.images
          "
          :src="activityDetails.images[0]"
          loop
          controls
          width="100%"
          height="280"
        ></video>

        <img
          v-if="!isPlayMusic"
          @click="handleAudioEvent(1)"
          style="z-index: 2; position: fixed; right: .56rem; top: 2.4rem"
          src="../assets/guan.png"
          alt=""
        />
        <img
          v-else
          @click="handleAudioEvent(0)"
          :style="{
            animation: isPlayMusic ? 'rotating 3s infinite linear' : '',
          }"
          style="z-index: 2; position: fixed; right: .56rem; top: 1.2rem"
          src="../assets/kai.png"
          alt=""
        />

        <audio ref="audio" controls style="display: none" loop>
          <source :src="activityDetails.mp3_url" type="audio/mpeg" />
        </audio>

        <div
          @click="handlePosterState(1)"
          style="
            z-index: 2;
            position: fixed;
            right: 1.04rem;
            top: 12rem;
            color: #fff;
            text-align: center;
            font-size: .96rem;
            background: #eb6100;
            font-weight: bold;
            width: 2.56rem;
            padding: .96rem;
            border: .4rem solid #fff;
            border-radius: 50%;
          "
        >
          生成海报
        </div>

        <div
          @click="handleComplaint"
          style="
            z-index: 2;
            position: fixed;
            right: 1.04rem;
            top: 6.4rem;
            color: #fff;
            text-align: center;
            font-size: .96rem;
            background: rgba(0, 0, 0, 0.4);
            width: 2rem;
            height: 2rem;
            line-height: 2rem;
            padding: .48rem;
            border-radius: 50%;
          "
        >
          投诉
        </div>
      </div>

      <div style="position: relative; height: 3.2rem; overflow: hidden">
        <img
          style="width: 100%; height: 100%; position: absolute"
          src="../assets/bj.png"
          alt=""
        />
        <div
          class="horizontal-scroll"
          style="
            font-size: 1.92rem;
            color: #fff;
            font-weight: bold;
            line-height: 3.2rem;
            color: transparent;
            -webkit-text-fill-color: #fff;
            -webkit-text-stroke: .08rem #b83030;
          "
        >
          <NoticeBar
            style="font-size: 1.92rem; font-weight: bold"
            scrollable
            background="transparent"
            :text="activityDetails.led_content"
          />
        </div>
      </div>

      <div
        style="
          height: 2.72rem;
          background: #e02020;
          color: #fff;
          font-size: 1.36rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 .8rem;
        "
      >
        <div>
          已报名人数[
          <span style="color: #ffff00">{{ activityDetails.num_order }}</span>
          ]
        </div>
        <div>
          <span style="color: #ffff00">{{ activityDetails.num_click }}</span>
          人浏览
        </div>
      </div>

      <div>
        <div style="height: 13.36rem; box-shadow: 0 .24rem 1.2rem #9b9b9b">
          <div style="background: #fff; height: 10rem">
            <div
              style="
                padding-top: 2.16rem;
                margin-bottom: .96rem;
                color: #000;
                font-size: 1.6rem;
                font-weight: bold;
                text-align: center;
              "
            >
              距活动结束还剩
            </div>

            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.36rem;
                margin-bottom: .96rem;
              "
            >
              <div style="display: flex; align-items: center">
                <div style="position: relative">
                  <img src="../assets/shijian.png" alt="" />
                  <div
                    style="
                      position: absolute;
                      color: #ffff00;
                      font-weight: bold;
                      top: 50%;
                      left: 50%;
                      transform: translate(-45%, -55%);
                    "
                  >
                    {{ countdown.day }}
                  </div>
                </div>
                <div style="margin: 0 .24rem">天</div>
              </div>

              <div style="display: flex; align-items: center">
                <div style="position: relative">
                  <img src="../assets/shijian.png" alt="" />
                  <div
                    style="
                      position: absolute;
                      color: #ffff00;
                      font-weight: bold;
                      top: 50%;
                      left: 50%;
                      transform: translate(-45%, -55%);
                    "
                  >
                    {{ countdown.hour }}
                  </div>
                </div>
                <div style="margin: 0 .24rem">时</div>
              </div>

              <div style="display: flex; align-items: center">
                <div style="position: relative">
                  <img src="../assets/shijian.png" alt="" />
                  <div
                    style="
                      position: absolute;
                      color: #ffff00;
                      font-weight: bold;
                      top: 50%;
                      left: 50%;
                      transform: translate(-45%, -55%);
                    "
                  >
                    {{ countdown.minute }}
                  </div>
                </div>
                <div style="margin: 0 .24rem">分</div>
              </div>

              <div style="display: flex; align-items: center">
                <div style="position: relative">
                  <img src="../assets/shijian.png" alt="" />
                  <div
                    style="
                      position: absolute;
                      color: #ffff00;
                      font-weight: bold;
                      top: 50%;
                      left: 50%;
                      transform: translate(-45%, -55%);
                    "
                  >
                    {{ countdown.seconds }}
                  </div>
                </div>
                <div style="margin: 0 .24rem">秒</div>
              </div>
            </div>
          </div>
          <div
            :style="{
              background: activityDetails.desc_background
                ? activityDetails.desc_background
                : '#F9E040',
            }"
            style="
              height: 3.44rem;
              font-size: 1.6rem;
              color: #000;
              font-weight: bold;
              line-height: 2.48rem;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            已有
            <span
              style="
                font-size: 1.36rem;
                color: #ffff00;
                padding: 0 .32rem;
                margin-left: .64rem;
                border-radius: .24rem;
                background: #000;
              "
            >
              {{
                activityDetails.num_order_str
                  ? activityDetails.num_order_str[0]
                  : 0
              }}
            </span>
            <span
              style="
                font-size: 1.36rem;
                color: #ffff00;
                padding: 0 .32rem;
                margin: 0 .32rem;
                border-radius: .24rem;
                background: #000;
              "
            >
              {{
                activityDetails.num_order_str
                  ? activityDetails.num_order_str[1]
                  : 0
              }}
            </span>
            <span
              style="
                font-size: 1.36rem;
                color: #ffff00;
                padding: 0 .32rem;
                margin-right: .32rem;
                border-radius: .24rem;
                background: #000;
              "
            >
              {{
                activityDetails.num_order_str
                  ? activityDetails.num_order_str[2]
                  : 0
              }}
            </span>
            <span
              style="
                font-size: 1.36rem;
                color: #ffff00;
                padding: 0 .32rem;
                margin-right: .64rem;
                border-radius: .24rem;
                background: #000;
              "
            >
              {{
                activityDetails.num_order_str
                  ? activityDetails.num_order_str[3]
                  : 0
              }}
            </span>
            人参加活动
          </div>
        </div>

        <div style="positon: relative; overflow: hidden; height: 22.48rem">
          <img
            style="position: absolute; z-index: -1; width: 100vw"
            src="../assets/1.png"
            alt=""
          />
          <div ref="srcoll1" class="srcoll1">
            <div
              v-for="(item, index) in activityDetails.lastBuyed"
              :key="index"
              style="text-align: center; width: 17.5%"
            >
              <!-- <div style="border-radius: 50%; background: #FFF400; width: 4.56rem; height: 4.56rem; margin-bottom: .48rem;"></div> -->
              <img
                :src="item.avatar_url"
                alt=""
                style="
                  border-radius: 50%;
                  width: 4.56rem;
                  height: 4.56rem;
                  margin-bottom: .48rem;
                "
              />
              <div style="font-size: 1.12rem; color: #fff; font-weight: bold">
                {{ item.real_name }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        :style="{
          background: activityDetails.desc_background
            ? activityDetails.desc_background
            : '#fff100',
        }"
        style="
          box-shadow: 0 -0.24rem 1.2rem #9b9b9b;
          box-sizing: border-box;
          padding: 1.28rem 1.44rem 1.84rem;
        "
      >
        <div
          class="details-content"
          style="
            background: #fff;
            width: calc(100vw - 2.88rem);
            height: 100%;
            font-size: 1.28rem;
            font-weight: 400;
          "
        >
          <div
            v-html="activityDetails.event_content"
            style="padding-bottom: .88rem; word-wrap: break-word; font-size: 0"
          ></div>
        </div>
      </div>

      <div
        :style="{
          background: activityDetails.desc_background
            ? activityDetails.desc_background
            : '#FFB006',
        }"
        style="padding: 1.12rem .88rem .64rem .96rem"
      >
        <div
          style="
            text-align: center;
            color: #fff;
            font-size: 1.6rem;
            font-weight: bold;
            background: #f68724;
            border-radius: 1.36rem;
            box-shadow: 0 .4rem .4rem rgba(0, 0, 0, 0.6);
            margin: 0 auto .88rem;
            width: 11.2rem;
            height: 2.8rem;
            line-height: 2.8rem;
          "
        >
          商品列表
        </div>

        <div
          style="
            width: calc(100vw - 1.84rem);
            height: 16.48rem;
            overflow-x: auto;
            display: flex;
            align-items: center;
          "
        >
          <div
            v-for="(item, index) in activityDetails.goods"
            :key="index"
            style="
              min-width: calc(100vw - 1.84rem);
              height: 100%;
              margin: 0 .4rem;
              width: 100%;
              background: #fff;
              border-radius: .4rem;
              padding: 1.2rem .88rem 1.2rem 1.04rem;
              box-sizing: border-box;
            "
          >
            <div style="display: flex; align-items: center">
              <!-- <div style="width: 6.08rem; height: 6.08rem; background: #F68724; border-radius: 1.2rem; margin-right: 1.04rem;"></div> -->
              <img
                :src="item.good_image"
                alt=""
                style="
                  width: 6.08rem;
                  height: 6.08rem;
                  border-radius: 1.2rem;
                  margin-right: 1.04rem;
                "
              />

              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  flex-direction: column;
                  height: 6.08rem;
                  flex: 1;
                "
              >
                <div style="color: #000; font-size: 1.28rem; line-height: 1.28rem">
                  {{ item.good_name }}
                </div>
                <div>
                  <div
                    style="
                      color: #b0b0b0;
                      font-size: .96rem;
                      display: flex;
                      lign-items: center;
                      line-height: .96rem;
                      font-weight: bold;
                    "
                  >
                    <div style="text-decoration: line-through">
                      ¥{{ item.old_price }}
                    </div>
                    <div style="margin-left: 1.68rem">
                      仅剩{{ item.good_qty }}件
                    </div>
                  </div>

                  <div
                    style="
                      font-size: .96rem;
                      color: #eb0b25;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      font-weight: bold;
                    "
                  >
                    <div v-if="item.pay_model === 'sale'">
                      {{ item.tuan_member }}人团价 ¥{{ item.sale_price }}
                    </div>
                    <div v-else-if="item.pay_model === 'deposit'">
                      拼团价 ¥{{ item.sale_price }}
                    </div>
                    <div
                      v-if="
                        item.lastTuan.length !== 0 &&
                        (item.self_buy_total !== 0 ||
                          item.self_buygroup_total !== 0)
                      "
                      @click="handleShowRegistrationInfo(null, 0, -1)"
                      style="
                        text-align: center;
                        color: #fff;
                        font-size: 1.12rem;
                        font-weight: bold;
                        width: 7.36rem;
                        line-height: 1.92rem;
                        height: 1.92rem;
                        border-radius: .32rem;
                        background: #eb0b25;
                      "
                    >
                      招募团队
                    </div>
                    <div
                      v-else
                      @click="handleShowRegistrationInfo(item, 1)"
                      style="
                        text-align: center;
                        color: #fff;
                        font-size: 1.12rem;
                        font-weight: bold;
                        width: 7.36rem;
                        line-height: 1.92rem;
                        height: 1.92rem;
                        border-radius: .32rem;
                        background: #eb0b25;
                      "
                    >
                      我要当团长
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style="margin: 1.76rem 0 0 .4rem">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  color: #000;
                  font-size: .96rem;
                  margin-bottom: .8rem;
                "
              >
                {{
                  item.lastTuan.length !== 0 ? item.lastTuan.length : 0
                }}人在拼团，可直接参加

                <div
                  style="
                    text-align: center;
                    padding: 0 .8rem;
                    color: #fff;
                    font-size: 1.12rem;
                    font-weight: bold;
                    line-height: 1.92rem;
                    height: 1.92rem;
                    border-radius: .32rem;
                    background: #eb0b25;
                  "
                  @click="handleJoin"
                >
                  更多
                </div>
              </div>

              <div
                v-if="item.lastTuan.length !== 0"
                style="height: 4.16rem; overflow-y: auto"
              >
                <div ref="srcoll2">
                  <div
                    v-for="(item1, index1) in item.lastTuan"
                    :key="index1"
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-bottom: .32rem;
                    "
                  >
                    <div style="display: flex; align-items: center">
                      <!-- <div style="width: 1.92rem; height: 1.92rem; border-radius: 50%; background: #fff400; margin-right: .8rem;"></div> -->
                      <img
                        :src="item1.avatar"
                        alt=""
                        style="
                          width: 1.92rem;
                          height: 1.92rem;
                          border-radius: 50%;
                          margin-right: .8rem;
                        "
                      />
                      <div style="color: #5d5d5d; font-size: 1.12rem">
                        {{ item1.nickname }}
                      </div>
                    </div>

                    <div style="display: flex; align-items: center">
                      <div
                        style="
                          color: #5d5d5d;
                          font-size: 1.12rem;
                          margin-right: .48rem;
                        "
                      >
                        当前{{ item1.joined_member }}人
                      </div>
                      <div
                        @click="handleShowRegistrationInfo(item, 2, index1)"
                        style="
                          text-align: center;
                          color: #eb0b25;
                          font-size: .96rem;
                          width: 4.64rem;
                          height: 1.44rem;
                          line-height: 1.44rem;
                          box-sizing: border-box;
                          border-radius: .72rem;
                          border: .08rem solid #eb0b25;
                        "
                      >
                        参与拼团
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-bottom: 1.04rem">
        <div
          style="
            color: #fff;
            font-size: 1.36rem;
            width: 20.96rem;
            height: 3.28rem;
            border-radius: .4rem;
            background: #d6400c;
            line-height: 3.28rem;
            text-align: center;
            margin: 1.76rem auto 1.84rem;
          "
        >
          最新订单记录
        </div>

        <!-- <div style="height: 18.88rem; overflow: hidden"> -->
        <!-- <div ref="srcoll3">
            <div
              v-for="(item, index) in activityDetails.lastBuyed"
              :key="index"
              style="
                margin: 0 .96rem .56rem 1.2rem;
                padding: 0 1.28rem 0 .64rem;
                border-radius: .4rem;
                height: 3.52rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: linear-gradient(90deg, #fff, #f3c294, #f5cd61);
              "
            >
              <div style="display: flex; align-items: center">
                <img
                  :src="item.avatar_url"
                  alt=""
                  style="
                    width: 2.4rem;
                    height: 2.4rem;
                    border-radius: 50%;
                    margin-right: .56rem;
                  "
                />
                <div
                  style="
                    margin-right: 1.12rem;
                    color: #252525;
                    font-size: 1.36rem;
                    max-width: 11.2rem;
                  "
                  class="overflow-text"
                >
                  {{ item.real_name }}
                  <div style="color: #252525; font-size: 1.28rem">
                    {{ item.telphone ? item.telphone : "" }}
                  </div>
                </div>
              </div>

              <div>
                <div
                  style="color: #ff7418; font-size: 1.12rem; margin-bottom: .24rem"
                >
                  {{ item.pay_status_name }} {{ item.order_money }}元
                </div>
                <div style="color: #3f3d37; font-size: .96rem">
                  {{ item.createtime }}
                </div>
              </div>
            </div>
          </div> -->

        <vueSeamlessScroll
          ref="seamlessScroll"
          class="warp"
          style="height: 18.88rem; overflow: hidden"
          :data="activityDetails.lastBuyed"
          :class-option="{ step: 0.5 }"
        >
          <div class="ul-scoll">
            <div
              v-for="(item, index) in activityDetails.lastBuyed"
              :key="index"
              style="
                margin: 0 .96rem .56rem 1.2rem;
                padding: 0 1.28rem 0 .64rem;
                border-radius: .4rem;
                height: 3.52rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: linear-gradient(90deg, #fff, #f3c294, #f5cd61);
              "
            >
              <div style="display: flex; align-items: center">
                <img
                  :src="item.avatar_url"
                  alt=""
                  style="
                    width: 2.4rem;
                    height: 2.4rem;
                    border-radius: 50%;
                    margin-right: .56rem;
                  "
                />
                <div
                  style="
                    margin-right: 1.12rem;
                    color: #252525;
                    font-size: 1.36rem;
                    max-width: 11.2rem;
                  "
                  class="overflow-text"
                >
                  {{ item.real_name }}
                  <div style="color: #252525; font-size: 1.28rem">
                    {{ item.telphone ? item.telphone : "" }}
                  </div>
                </div>
              </div>

              <div>
                <div
                  style="color: #ff7418; font-size: 1.12rem; margin-bottom: .24rem"
                >
                  {{ item.pay_status_name }} {{ item.order_money }}元
                </div>
                <div style="color: #3f3d37; font-size: .96rem">
                  {{ item.createtime }}
                </div>
              </div>
            </div>
          </div>
        </vueSeamlessScroll>
        <!-- </div> -->
      </div>

      <div style="height: 3.68rem"></div>
      <div
        v-if="
          activityDetails.admin_group_id === 0 &&
          activityDetails.self_buy_total === 0 &&
          activityDetails.self_buygroup_total === 0
        "
        style="
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100vw;
          height: 3.68rem;
          color: #fff;
          display: flex;
          align-items: center;
        "
      >
        <div
          @click="
            handleShowRegistrationInfo(
              activityDetails.goods[0],
              1,
              -1,
              activityDetails.goods.length > 1
            )
          "
          style="
            flex: 1;
            height: 100%;
            background: #fa8822;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          "
        >
          <div>
            <span style="font-size: 1.04rem"
              >{{
                activityDetails.goods && activityDetails.goods[0].sale_price
                  ? activityDetails.goods[0].sale_price
                  : 0
              }}元</span
            >
          </div>
          <div style="font-size: 1.36rem">我要当团长</div>
        </div>
        <template v-if="activityDetails.invite_group_id !== 0">
          <div
            style="background: #fff; width: 1em; height: 100%; font-size: .08rem"
          ></div>
          <div
            @click="isShowOpenTeamDialog = true"
            style="
              font-size: 1.36rem;
              flex: 1;
              background: #fa8822;
              text-align: center;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            参加好友的团
          </div>
        </template>
      </div>
      <div
        v-else
        style="
          box-shadow: 0 .24rem 1.2rem #9b9b9b;
          text-align: center;
          display: flex;
          align-items: center;
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100vw;
          height: 3.68rem;
          line-height: 3.68rem;
        "
      >
        <div
          style="
            flex: 1;
            color: #f68724;
            font-size: 1.12rem;
            display: flex;
            align-items: center;
            background: #fff;
          "
        >
          <div @click="goManagementPage" style="flex: 1">进入后台</div>
          <div @click="isShowMerchantsInfo = true" style="flex: 1">
            联系商家
          </div>
        </div>
        <div
          @click="handleShowRegistrationInfo(null, 0, -1)"
          style="flex: 1; background: #f88822; color: #fff; font-size: 1.36rem"
        >
          招募团员赚佣金
        </div>
      </div>
    </div>
    <div v-else>
      <img
        style="width: 100vw; height: 100vh"
        :src="activityDetails.close_show_image"
        alt=""
      />
    </div>

    <!-- 填写信息弹框 -->
    <el-dialog
      title="填写信息"
      :visible.sync="isShowRegistrationInfo"
      width="23.76rem"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div>
        <el-input
          v-model="registrationInfo.userName"
          placeholder="请输入学生姓名"
        ></el-input>

        <el-input
          v-model="registrationInfo.userPhone"
          placeholder="请输入家长联系电话"
          style="margin-top: 1.2rem"
        ></el-input>

        <div
          v-for="(item, index) in registrationInfos"
          :key="index"
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <template v-if="item.type === 'text'">
            <el-input
              style="margin-top: .8rem"
              v-model="item.value"
              :placeholder="`${item.label}${
                item.isRequired ? '（默认必填）' : ''
              }`"
            ></el-input>
          </template>
          <template v-else-if="item.type === 'checkox'">
            <div>
              <div style="margin: .4rem 0; color: #000">
                {{ item.label }}{{ item.isRequired ? "（默认必填）" : "" }}
              </div>
              <el-checkbox-group
                v-model="item.value"
                :max="item.maxSelectCount"
                style="display: inline-block; margin-right: .8rem"
              >
                <el-checkbox
                  v-for="(item1, index1) in item.options"
                  :key="index1"
                  :label="index1"
                  name="type"
                  >{{ item1 }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </template>
          <template v-else-if="item.type === 'raido'">
            <div>
              <div style="margin: .4rem 0; color: #000">
                {{ item.label }}{{ item.isRequired ? "（默认必填）" : "" }}
              </div>
              <el-radio-group v-model="item.value">
                <el-radio
                  v-for="(item1, index1) in item.options"
                  :key="index1"
                  :label="index1"
                  >{{ item1 }}</el-radio
                >
              </el-radio-group>
            </div>
          </template>
        </div>

        <div
          @click="handleConfirmInfo"
          style="
            background: #f68624;
            font-size: 1.2rem;
            color: #fff;
            height: 2.8rem;
            line-height: 2.8rem;
            border-radius: .8rem;
            text-align: center;
            margin-top: 1.6rem;
          "
        >
          确认并更新报名信息
        </div>

        <div
          style="
            color: #d1a074;
            font-size: 1.04rem;
            line-height: 1.6rem;
            font-weight: 400;
            margin-top: 1.6rem;
          "
        >
          你的信息（头像、姓名）仅用于活动展示多次填写报名以最后一次为准。
        </div>
      </div>
    </el-dialog>

    <!-- 展示海报 -->
    <div v-if="isShowPoster">
      <div class="mask"></div>
      <div
        style="
          z-index: 3001;
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 82%;
        "
      >
        <div
          style="display: flex; align-items: center; justify-content: flex-end"
        >
          <div
            style="
              color: #fff;
              font-size: 1.28rem;
              background: #ff7418;
              padding: .4rem 1.2rem;
              border-radius: .56rem;
            "
          >
            长按图片保存相册
          </div>
          <img
            @click="handlePosterState(0)"
            style="width: 1.84rem; height: 1.84rem; margin-left: 4rem"
            src="../assets/close.png"
            alt=""
          />
        </div>

        <div
          style="
            margin-top: .8rem;
            background: #ffb400;
            border-radius: 1.2rem;
            height: 80vh;
            position: relative;
          "
        >
          <img
            v-if="postersUrl"
            style="width: 100%; height: 100%; border: none"
            :src="postersUrl"
            alt=""
          />
          <div
            v-else
            style="
              color: #f7f2f3;
              font-size: 1.12rem;
              text-align: center;
              position: absolute;
              width: 100%;
              top: 10%;
            "
          >
            海报背景-由设计师审核的时候设计上传
          </div>

          <!-- <div
            style="
              position: absolute;
              width: 100%;
              height: 7.28rem;
              box-sizing: border-box;
              border-radius: .48rem;
              background: #fff;
              left: 0;
              bottom: .64rem;
              padding: .64rem 1.2rem .64rem 1.2rem;
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <div>
              <div style="font-weight: 400; display: flex; align-items: center">
                <img
                  :src="userInfo.avatar"
                  alt=""
                  style="
                    margin-right: .48rem;
                    border-radius: 50%;
                    width: 3.2rem;
                    height: 3.2rem;
                  "
                />
                <div>
                  <div style="color: #202020; font-size: 1.28rem">
                    {{ userInfo.nickname }}
                  </div>
                  <div style="color: #686767; font-size: .96rem">
                    邀请您参加本活动
                  </div>
                </div>
              </div>
              <div style="color: #ff2a00; font-size: 1.12rem; margin-top: .48rem">
                长按识别二维码立即秒杀
              </div>
            </div>

            <div
              class="qrcode"
              ref="qrCodeUrl"
              style="border-radius: .48rem; width: 6rem; height: 6rem"
            ></div>
          </div> -->
        </div>

        <div class="refresh-wrap" @click="getPostersContent">
          <i class="el-icon-refresh-right"></i>
          <span>看不到二维码，点我刷新</span>
        </div>
      </div>
    </div>

    <!-- 选择课程弹框 -->
    <el-drawer
      :visible.sync="isShowSelctCourse"
      direction="btt"
      :before-close="handleClose"
    >
      <div style="margin: 2.4rem 2.4rem; font-weight: 400; position: relative">
        <div style="display: flex; align-items: center">
          <!-- <div style="background: #eb0b25; border-radius: .48rem; width: 3.2rem; height: 3.2rem;"></div> -->
          <img
            :src="
              activityDetails.goods
                ? activityDetails.goods[currentSelectGoodIndex].good_image
                : ''
            "
            alt=""
            style="border-radius: .48rem; width: 3.2rem; height: 3.2rem"
          />

          <div
            style="
              margin-left: .56rem;
              display: flex;
              justify-content: space-between;
              flex-direction: column;
            "
          >
            <div style="display: flex; align-items: center; font-size: .96rem">
              <div style="color: #eb0b25">
                ￥
                <span style="font-size: 1.12rem; font-weight: bold">{{
                  activityDetails.goods
                    ? activityDetails.goods[currentSelectGoodIndex].sale_price
                    : ""
                }}</span>
              </div>
              <div
                style="
                  color: #c3c3c3;
                  text-decoration: line-through;
                  margin-left: .8rem;
                "
              >
                ￥{{
                  activityDetails.goods
                    ? activityDetails.goods[currentSelectGoodIndex].old_price
                    : ""
                }}
              </div>
            </div>
            <div style="color: #c3c3c3; font-size: .88rem">
              剩余{{
                activityDetails.goods
                  ? activityDetails.goods[currentSelectGoodIndex].good_qty
                  : ""
              }}件
            </div>
          </div>
        </div>

        <div
          class="course-list"
          style="height: 18.8rem; overflow-y: auto; margin-top: 1.6rem"
        >
          <div
            @click="handleChangeSelectGood(index)"
            v-for="(item, index) in activityDetails.goods"
            :key="index"
            :class="[index === currentSelectGoodIndex ? 'selected' : '']"
            style="
              margin-top: .96rem;
              box-sizing: border-box;
              font-size: .96rem;
              color: #565656;
              height: 2.4rem;
              line-height: 2.4rem;
              padding: 0 1.2rem;
              background: #eeeeee;
              border-radius: .32rem;
            "
          >
            {{ item.good_name }}
          </div>
        </div>

        <div style="height: 4.8rem"></div>
        <div
          @click="handleConfirmSelectGood"
          style="
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 3.2rem;
            line-height: 3.2rem;
            text-align: center;
            color: #fff;
            border-radius: 1.2rem;
            background: #eb0b25;
            font-size: 1.12rem;
          "
        >
          选择课程
        </div>
      </div>
    </el-drawer>

    <!-- 商家信息弹框 -->
    <el-dialog
      title="商家信息"
      :visible.sync="isShowMerchantsInfo"
      width="27.2rem"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div style="font-weight: 400">
        <div v-for="(item, index) in merchants" :key="index">
          <div style="font-size: 1.2rem; color: #393939">{{ item.name }}</div>

          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: .8rem;
              font-size: 1.12rem;
            "
          >
            <div style="color: #787878">地址：{{ item.street }}</div>
            <!-- <div
              @click="handleShowMap(item)"
              style="
                color: #fff;
                background: #ff7418;
                width: 6.4rem;
                height: 1.92rem;
                line-height: 1.92rem;
                border-radius: .8rem;
                text-align: center;
              "
            >
              导航
            </div> -->
          </div>

          <div
            v-for="(item1, index1) in item.telphone"
            :key="index1"
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: .8rem;
              font-size: 1.12rem;
            "
          >
            <div style="color: #787878">电话：{{ item1.value }}</div>
            <div
              @click="handleMakePhone(item1.value)"
              style="
                color: #fff;
                background: #00a0e8;
                width: 6.4rem;
                height: 1.92rem;
                line-height: 1.92rem;
                border-radius: .8rem;
                text-align: center;
              "
            >
              拨号
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 所有拼团列表弹框 -->
    <el-dialog
      title="招募团员"
      :visible.sync="isShowJoinMd"
      width="27.2rem"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="join-list">
        <div class="join-item" v-for="item in joinList" :key="item.id">
          <div class="join-avatar">
            <img :src="item.avatar" alt="" />
          </div>
          <div class="join-info">
            <div>{{ item.nickname }}</div>
            <div>
              还差 <span>{{ item.need_people }}</span> 人成团
            </div>
          </div>

          <div class="join-btn" @click="handleShowRegistrationInfoJoin(item)">
            参加拼团
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 没有报名弹框 -->
    <div v-if="isShowOpenTeamDialog">
      <div class="mask" style="z-index: 1999"></div>
      <div
        style="
          z-index: 2000;
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 26.4rem;
          border-radius: .8rem;
          box-sizing: border-box;
          padding: .8rem;
          background: #fff;
        "
      >
        <i
          @click="isShowOpenTeamDialog = false"
          class="el-icon-circle-close"
          style="
            font-size: 1.92rem;
            color: #fff;
            position: absolute;
            top: -2.8rem;
            right: 0;
          "
        ></i>

        <div>
          <p style="font-size: 1.12rem; text-align: center">
            还有还差
            <!--  activityDetails.invite_group_buyer_count === 0
                ? 1
                : activityDetails.invite_group_buyer_count -->
            <span style="color: red">{{
              activityDetails.invite_group_buyer_count
            }}</span>
            人即可享受成团福利
          </p>
          <div>
            <div
              v-if="activityDetails.goods && activityDetails.goods.length !== 0"
              style="display: flex; align-items: center"
            >
              <div
                style="
                  width: 6.4rem;
                  height: 6.4rem;
                  overflow: hidden;
                  margin-right: .8rem;
                  flex-shrink: 0;
                  align-self: flex-start;
                "
              >
                <img
                  :src="activityDetails.goods[0].good_image"
                  alt=""
                  style="width: 100%"
                />
              </div>
              <div style="flex: 1; font-size: 1.04rem; font-weight: 400">
                <div>{{ activityDetails.goods[0].good_name }}</div>
                <div
                  style="
                    margin: .8rem 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  支付
                  <span style="color: red">{{
                    activityDetails.goods[0].sale_price
                  }}</span>
                  元
                </div>
                <div
                  @click="
                    handleShowRegistrationInfo(activityDetails.goods[0], 2, -2)
                  "
                  style="
                    background: #fb2848;
                    border-radius: 1.2rem;
                    text-align: center;
                    color: #fff;
                    line-height: 2.4rem;
                  "
                >
                  立即参加好友的团
                </div>
                <div
                  style="display: flex; align-items: center; flex-wrap: wrap"
                >
                  <div
                    v-for="(item, index) in activityDetails.invite_group_buyer"
                    :key="index"
                  >
                    <div style="margin: 1.2rem 2rem 0 0">
                      <div
                        v-if="item"
                        style="
                          width: 4rem;
                          height: 4rem;
                          border-radius: 50%;
                          overflow: hidden;
                          position: relative;
                        "
                      >
                        <img
                          :src="item.avatar_url"
                          alt=""
                          style="width: 100%"
                        />
                        <div
                          v-if="index === 0"
                          style="
                            position: absolute;
                            color: #484848;
                            text-align: center;
                            font-size: .8rem;
                            width: 100%;
                            transform: translateX(-50%);
                            left: 50%;
                            bottom: 0;
                            background: #ffcc00;
                            border-radius: .4rem;
                          "
                        >
                          团长
                        </div>
                      </div>
                      <template v-else>
                        <div
                          style="
                            height: 3.6rem;
                            width: 3.6rem;
                            border-radius: 50%;
                            overflow: hidden;
                            background: #efeff0;
                            position: relative;
                          "
                        >
                          <img
                            src="../assets/user.png"
                            style="
                              width: 100%;
                              padding: .4rem;
                              box-sizing: border-box;
                              margin-top: .4rem;
                            "
                            alt=""
                          />
                        </div>
                        <div
                          style="
                            font-szie: 1.04rem;
                            color: #4f4b4c;
                            text-align: center;
                          "
                        >
                          待招募
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 错误提示 -->
    <div v-if="isShowErrorHint">
      <div class="mask"></div>
      <div
        style="
          z-index: 3001;
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 24rem;
          height: 14.4rem;
          border-radius: .8rem;
          box-sizing: border-box;
          padding: 1.44rem 2.32rem;
          background: #fff;
        "
      >
        <div style="font-size: 1.28rem; color: #393939; text-align: center">
          提示
        </div>

        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.28rem;
            margin-top: 2.4rem;
            font-weight: 400;
          "
        >
          <div style="color: #393939">{{ errorContent }}</div>
        </div>

        <div
          @click="isShowErrorHint = false"
          style="
            position: absolute;
            left: 0;
            bottom: 0;
            box-sizing: border-box;
            width: 100%;
            border-top: .08rem solid .08rem solid #eceaea;
            padding: 0 2.32rem;
          "
        >
          <div
            style="
              width: 9.28rem;
              height: 4rem;
              font-weight: 400;
              color: #3e3e3e;
              font-size: 1.28rem;
              text-align: center;
              line-height: 4rem;
              margin: auto;
            "
          >
            确 认
          </div>
        </div>
      </div>
    </div>

    <!-- 提示 -->
    <div v-if="isShowHint">
      <div
        style="
          z-index: 3003;
          color: #fff;
          font-weight: 400;
          font-size: .96rem;
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: rgba(0, 0, 0, 0.6);
          border-radius: .32rem;
          padding: .8rem 1.2rem;
        "
      >
        {{ hintContent }}
      </div>
    </div>
  </div>
</template>

<script>
// import QRCode from 'qrcodejs2'
import vueSeamlessScroll from 'vue-seamless-scroll'
import { isExistValOfLocalStorage,randomString } from '../utils/common'
import { NoticeBar } from 'vant';
export default {
  name: "Home",
  components: {
    vueSeamlessScroll,
    NoticeBar
  },
  data() {
    return {
      // 是否显示报名弹框
      isShowOpenTeamDialog: false,
      // 是否显示报名信息
      isShowRegistrationInfo: false,
      // 报名信息
      registrationInfo: {
        userName: '',
        userPhone: '',
      },
      registrationInfos: [],
      // 是否展示海报
      isShowPoster: false,
      // 是否显示选择课程弹框
      isShowSelctCourse: false,
      // 是否显示商家信息弹框 
      isShowMerchantsInfo: false,
      // 是否显示错误提示
      isShowErrorHint: false,
      // 错误提示内容
      errorContent: '',
      // 活动id
      id: "",
      // 推荐人id
      invite_id: "",
      // 活动详情
      activityDetails: {},
      // 倒计时的定时器
      countdownTimer: null,
      // 当前的倒计时内容
      countdown: {
        day: 0,
        hour: 0,
        minute: 0,
        seconds: 0,
      },
      // 商家信息
      merchants: [],
      // 是否显示提示
      isShowHint: false,
      // 提示内容
      hintContent: '',
      // 用户信息
      userInfo: {},
      // 当前选择的商品
      currentSelectGood: {},
      // 当前下单的类型  0: 招募团队 1: 我要开团  2: 参与拼团
      currentOrderType: -1,
      // 当前选择的拼团人的下标
      currentSpellGroupIndex: -1,
      // 当前选择的课程下标
      currentSelectGoodIndex: 0,
      // 是否需要显示选择多个商品的弹框
      isNeedShowSelectGoods: false,
      // 生成的二维码
      qrCode: null,
      // 当前音乐是否播放
      isPlayMusic: false,
      // 海报图片
      postersUrl: "",
      load: false,

      isShowJoinMd: false,
      joinList: []
    }
  },
  mounted() {
    const { id, invite_id } = this.$route.query;
    this.id = parseInt(id);
    this.invite_id = parseInt(invite_id);
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.getActivityDetails(this.id);
    if (isExistValOfLocalStorage('tempData')) {
    localStorage.removeItem('tempData');
    }
    this.handleShareLink();
  },
  beforeDestroy() {
    clearInterval(this.countdownTimer);
  },
  methods: {

    /** 获取活动海报内容 */
    getPostersContent() {
      this.$axios.post("/index/getshareImage", {
        id: this.id,
        invite_id: this.invite_id,
      }).then(res => {
        this.postersUrl = res.image_url;
      }).catch(error => {
        this.showMessage(error);
      });
    },


    /** 处理音频事件  0 关闭  1 打开  */
    handleAudioEvent(type) {
      this.isPlayMusic = type === 1;
      if (type === 0) {
        this.$refs.audio.pause();
      }
      else if (type === 1) {
        if (this.$refs.audio) {
          this.$refs.audio.load();
          this.$refs.audio.play();
        }
      }
    },


    /** 创建二维码 */
    // creatQrCode() {
    //   this.$refs.qrCodeUrl.textContent = ''
    //   this.qrCode = new QRCode(this.$refs.qrCodeUrl, {
    //     text: "http://pp.ihycc.cn/event_biginfo?id=" + this.id + "&invite_id=" + this.userInfo.id,
    //     width: 75,
    //     height: 75,
    //     colorDark: '#ffffff',
    //     colorLight: '#F05A11',
    //     correctLevel: QRCode.CorrectLevel.H
    //   })
    //   console.log(this.qrCode, 'addd')
    // },

    /** 处理分享操作 */
    handleShareLink() {
      // this.copyText = "http://pp.ihycc.cn/event_biginfo?id=" + this.activityDetails.id + "&invite_id=" + this.userInfo.id;
      // new this.Clipboard('.copy-text');

      this.$axios.post("/Wechat/getShareConfig", {
        url: window.location.href,
        event_id: this.id
      }).then(res => {
        this.wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.appId, // 必填，公众号的唯一标识
          timestamp: res.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.nonceStr, // 必填，生成签名的随机串
          signature: res.signature,// 必填，签名
          jsApiList: ["onMenuShareAppMessage"] // 必填，需要使用的JS接口列表
        });

        this.wx.ready(() => {
          let title = this.activityDetails.share_title ? this.activityDetails.share_title : ''
          let desc = this.activityDetails.share_content ? this.activityDetails.share_content : ""
          let link = "http://pp.ihycc.cn/event_"+randomString()+"?id=" + this.id + "&invite_id=" + this.userInfo.id
          let imgUrl = this.activityDetails.share_image ? this.activityDetails.share_image : ''
          this.wx.onMenuShareAppMessage({
            // 分享标题
            title,
            // 分享描述
            desc,
            // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            link,
            // 分享图标 
            imgUrl,
            success: () => {
              // 设置成功
              // this.showMessage("右上角分享");
              this.handleStatisticsShare();
            },
          });
          // 分享到朋友圈
          // this.wx.onMenuShareTimeline({
          //     // 分享标题
          //     title , 
          //     // 分享描述
          //     desc, 
          //     // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          //     link: link,
          //     // 分享图标 
          //     imgUrl,
          //     success: () => {
          //         // 设置成功
          //         // this.showMessage("右上角分享");
          //         this.handleStatisticsShare();
          //     },
          // })

        });
      });
    },

    /** 统计用户分享操作 */
    handleStatisticsShare() {
      this.$axios.post("/events/addShareClick", {
        event_id: this.id,
      });
    },

    /** 处理点击导航展示的地图 */
    handleShowMap(item) {
      // 记录当前是去导航的状态
      localStorage.setItem("tempData", true);
      if (item.lat && item.lng && item.city) {
        location.href = `https://apis.map.qq.com/tools/poimarker?type=1&keyword=${item.city}&center=${item.lat},${item.lng}&radius=1000&key=C3SBZ-LTHCX-AIT4Q-TRZQV-MY6TH-QVFKO&referer=myapp`;
      }
      else if (item.address && item.city) {
        location.href = `https://apis.map.qq.com/tools/poimarker?type=1&keyword=${item.city}&region=${item.address}&radius=1000&key=C3SBZ-LTHCX-AIT4Q-TRZQV-MY6TH-QVFKO&referer=myapp`;
      }
    },

    /** 处理点击拨号 */
    handleMakePhone(phone) {
      window.location.href = 'tel:' + phone;
    },


    /** 处理确认选择商品 */
    handleConfirmSelectGood() {
      this.currentSelectGood = this.activityDetails.goods[this.currentSelectGoodIndex];

      // 我要开团...
      this.handleStartTeam({ good_id: this.currentSelectGood.id });
    },

    /** 处理切换选择的商品 */
    handleChangeSelectGood(index) {
      this.currentSelectGoodIndex = index;
    },

    /** 处理点击确认信息 */
    handleConfirmInfo() {
      console.log(this.currentSelectGood, 'dddd')
      if (this.clearStringSpace(this.registrationInfo.userName) === "" || this.clearStringSpace(this.registrationInfo.userPhone) === "") {
        this.showMessage('请填写报名信息');
        return;
      }
      for (const item of this.registrationInfos) {
        if (item.isRequired) {
          if (item.type === 'text' && this.clearStringSpace(item.value) === "") {
            this.showMessage('请填写报名信息');
            return;
          }
          else if (item.type === 'raido' && item.value === "") {
            this.showMessage('请填写报名信息');
            return;
          }
          else if (item.type === 'checkox' && item.value.length === 0) {
            this.showMessage('请填写报名信息');
            return;
          }
        }
      }

      // 如果是导师
      if (this.activityDetails.admin_group_id === 1) {
        this.showMessage("导师不允许" + (this.currentOrderType === 1 ? "开团" : this.currentOrderType === 2 ? "报名" : "此操作"));
        return;
      }

      // 如果有多个商品 并且需要显示选择商品的弹框 ...
      if (this.isNeedShowSelectGoods) {
        this.currentSelectGoodIndex = 0;
        this.isShowRegistrationInfo = false;
        this.isShowSelctCourse = true;
        return;
      }

      if (this.currentOrderType === 2 && this.currentSelectGood.my_buyed === 0 && this.currentSelectGood.lastTuan.length !== 0 && this.currentSelectGood.my_create_order === 0) {
        // 参与拼团...
        this.joinTeamActivity({
          good_id: this.currentSelectGood.id,
          group_id: this.currentSpellGroupIndex === -2 ? this.activityDetails.invite_group_id : this.currentSelectGood.lastTuan[this.currentSpellGroupIndex].order_sn
        }).then(res => {
          this.handleWXPayInterface(res.pay_id);
        }).catch(error => {
          this.showMessage(error);
        });
      }
      else {
        // 我要开团...
        this.handleStartTeam({ good_id: this.currentSelectGood.id }).then(res => {
          this.handleWXPayInterface(res.pay_id);
        }).catch(error => {
          this.showMessage(error);
        });
      }
    },

    /** 支付接口 */
    handleWXPayInterface(pay_id) {
      this.$axios.post("/Wechat/wechatPay", { pay_id }).then(res => {
        this.handleLocalPayment(res);
      }).catch(error => {
        this.showMessage(error);
      });
    },

    /** 调用本地支付接口 */
    handleLocalPayment(data) {
      // this.wx.config({
      //     debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      //     appId: data.appId, // 必填，公众号的唯一标识
      //     timestamp: data.timeStamp, // 必填，生成签名的时间戳
      //     nonceStr: data.nonceStr, // 必填，生成签名的随机串
      //     signature: data.paySign,// 必填，签名
      //     jsApiList: ["chooseWXPay", "closeWindow"] // 必填，需要使用的JS接口列表
      // });
      // this.wx.chooseWXPay({ 
      //     // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
      //     timestamp: data.timeStamp, 
      //     // 支付签名随机串，不长于 32 位   17712345678
      //     nonceStr: data.nonceStr, 
      //     // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
      //     package: data.package, 
      //     // 签名类型
      //     signType: data.signType,
      //     // 支付签名
      //     paySign: data.paySign, 
      //     success: res => {
      //         // 前端判断返回方式，微信团队郑重提示：不保证绝对可靠，切记！
      //         if (res.errMsg == 'chooseWXPay:ok') {
      //             // 【支付成功】 
      //         }
      //     },
      //     complete: res => {
      //         // 接口调用完成时执行的回调函数，无论成功或失败都会执行
      //         if (res.errMsg == 'chooseWXPay:ok') {
      //             // 【支付成功】：支付成功提示页面，点击完成按钮之后
      //             this.showMessage("支付成功");
      //             /* 关闭微信窗口，调用时需要在config中进行校验 */
      //             this.wx.closeWindow();
      //         } 
      //     },
      // });

      window.WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        data,
        function () {
          window.location.reload();
          // if(res.err_msg == "get_brand_wcpay_request:ok" ){
          //     // 使用以上方式判断前端返回,微信团队郑重提示：
          //     //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          //     this.showMessage("支付成功");
          //     // 跳转后台页面
          //     this.goManagementPage();
          // } 
        }
      );
    },


    /** 开团接口 */
    handleStartTeam(data) {
      return new Promise((resolve, reject) => {
        const { good_id } = data;
        this.$axios.post("/events/openTuan", {
          event_id: this.id,
          good_id,
          realname: this.registrationInfo.userName,
          telphone: this.registrationInfo.userPhone,
          add_data: JSON.stringify(this.registrationInfos),
        }).then(res => {
          this.getActivityDetails(this.id);
          this.isShowRegistrationInfo = false;
          this.isShowSelctCourse = false;
          resolve(res);
        }).catch(error => {
          reject(error);
        });
      });
    },

    /** 参加团队活动 */
    joinTeamActivity(data) {
      return new Promise((resolve, reject) => {
        const { good_id, group_id } = data;

        this.$axios.post("/events/joinTuan", {
          event_id: this.id,
          good_id,
          group_id,
          realname: this.registrationInfo.userName,
          telphone: this.registrationInfo.userPhone,
          add_data: JSON.stringify(this.registrationInfos),
        }).then(res => {
          this.getActivityDetails(this.id);
          this.isShowRegistrationInfo = false;
          this.isShowSelctCourse = false;
          resolve(res);
        }).catch(error => {
          reject(error);
        });
      });
    },

    handleJoin() {
      this.$axios.post("/events/getAllGroup", {
        event_id: this.id,
      }).then(res => {
        this.joinList = res
        this.isShowJoinMd = true
      })
    },

    /** 获取活动详情 */
    getActivityDetails(id) {
      // document.title = '需要设置的值';
      this.$axios.post("/events/getInfo", {
        id,
        invite_id: this.invite_id
      }).then(res => {
        let invite_group_buyer = [
          res.invite_group_buyer && res.invite_group_buyer[0] ? res.invite_group_buyer[0] : null,
          res.invite_group_buyer && res.invite_group_buyer[1] ? res.invite_group_buyer[1] : null,
          res.invite_group_buyer && res.invite_group_buyer[2] ? res.invite_group_buyer[2] : null,
        ];
        let invite_group_buyer_count = 0;
        invite_group_buyer.forEach(item => { invite_group_buyer_count = item === null ? invite_group_buyer_count + 1 : invite_group_buyer_count });

        this.activityDetails = {
          ...res,
          num_order_str: res.num_order.toString().padStart(4, "0"),
          images: res.images && res.images.length !== 0 ? res.images[0].file_url.split(",") : [],
          invite_group_buyer,
          invite_group_buyer_count,
        };
        this.merchants = this.activityDetails.school.map(item => ({ ...item, telphone: JSON.parse(item.telphone) }));

        this.registrationInfos = [];
        const fromJson = JSON.parse(res.from_json);
        if (fromJson instanceof Array) {
          this.registrationInfos = fromJson;
        }
        else {
          for (const key in fromJson) {
            this.registrationInfos.push(fromJson[key]);
          }
        }
        this.registrationInfos.splice(0, 2)

        localStorage.setItem("userInfo", JSON.stringify({
          ...JSON.parse(localStorage.getItem("userInfo")),
          admin_group_id: this.activityDetails.admin_group_id,
        }));

        if (
          this.activityDetails.admin_group_id === 0 &&
          this.activityDetails.invite_group_id !== 0 &&
          this.activityDetails.goods.length !== 0 &&
          this.activityDetails.goods[0].self_buy_total === 0 &&
          this.activityDetails.goods[0].self_buygroup_total === 0
        ) {
          this.isShowOpenTeamDialog = true;
        }

        // 活动倒计时
        this.countdownTimer = null;
        this.countdownTimer = setInterval(() => {
          const now = new Date().getTime();
          const end = new Date(this.activityDetails.end_time.replace(/-/g, '/')).getTime().toString().padEnd(13, '0')
          // const end = parseInt(new Date(this.activityDetails.end_time).getTime()
          const date = end - now;
          let day, hour, minute, seconds;

          if (date >= 0) {
            day = Math.floor(date / 1000 / 60 / 60 / 24).toString().padStart(2, '0');
            hour = Math.floor(date / 1000 / 60 / 60 % 24).toString().padStart(2, '0');
            minute = Math.floor(date / 1000 / 60 % 60).toString().padStart(2, '0');
            seconds = Math.floor(date / 1000 % 60).toString().padStart(2, '0');
            this.countdown = { day, hour, minute, seconds };
          }
          else {
            clearInterval(this.countdownTimer);
          }
        }, 1000);

        this.$nextTick(() => {
          if (this.$refs['srcoll1'].scrollHeight > 281) {
            this.handleSetAnimationXcrollY('srcoll1', this.activityDetails.lastBuyed.length * 0.3, 281);
          }
          for (let index in this.$refs['srcoll2']) {
            if (this.activityDetails.goods[index].lastTuan.length > 2) {
              this.handleSetAnimationXcrollY('srcoll2', this.activityDetails.goods[index].lastTuan.length * 1.4, 52, index);
            }
          }
          // if (this.$refs['srcoll3'].scrollHeight > 236) {
          //   this.handleSetAnimationXcrollY('srcoll3', this.activityDetails.lastBuyed.length * 0.5, 236);
          // }
        });

        // 修改页面标题
        document.title = this.activityDetails.name ? this.activityDetails.name : '拼团分销';
        let iframe = document.createElement('iframe');

        iframe.style.display = 'none';
        let fn = function () {
          setTimeout(function () {
            iframe.removeEventListener('load', fn);
            document.bodyNaNpxoveChild(iframe);
          }, 0);
        };
        iframe.addEventListener('load', fn);
        document.body.appendChild(iframe);

        this.load = true;

        // 播放音频
        this.wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: '', // 必填，公众号的唯一标识
          timestamp: 1, // 必填，生成签名的时间戳
          nonceStr: '', // 必填，生成签名的随机串
          signature: '',// 必填，签名
          jsApiList: [] // 必填，需要使用的JS接口列表
        });

        this.wx.ready(() => {
          this.handleAudioEvent(1);
        })
      });
    },

    /** 通过ref标题 设置垂直滚动样式 */
    handleSetAnimationXcrollY(ref, time, height, index = -1) {
      // 创建动画
      if (index === -1) {
        this.$refs[ref].style = `
                    animation: scroll-y ${time}s linear infinite;
                    transform: translateY(-${height}px);
                    margin-top: ${height}px;
                `;
      }
      else {
        this.$refs[ref][index].style = `
                    animation: scroll-y ${time}s linear infinite;
                    transform: translateY(-${height}px);
                    margin-top: ${height}px;
                `;
      }
    },

    /** 处理海报的显示与关闭  type: 0 关闭  1 打开 */
    handlePosterState(type) {
      if (this.activityDetails.admin_group_id === 1) {
        this.showMessage("导师不允许生成海报");
        return;
      }
      this.isShowPoster = type == 1;
      if (this.isShowPoster) {
        this.getPostersContent();
        // this.$nextTick(() => {
        //   this.creatQrCode();
        // });
      }
    },

    // 投诉
    handleComplaint() {
      this.$router.push({ path: '/complaint', query: { event_id: this.id } })
    },


    /** 显示填写报名信息弹框  0: 招募团队 1: 我要开团  2: 参与拼团 */
    handleShowRegistrationInfo(item, type, index = -1, isMultiple = false) {

      if (this.activityDetails.admin_group_id === 1 && type === 0) {
        this.showMessage("导师不允许分享");
        return;
      }
      else if (this.activityDetails.admin_group_id === 3 && (type === 1 || type === 2)) {
        this.showMessage("员工不允许" + (type === 1 ? "开团" : type === 2 ? "报名" : "此操作"));
        return;
      }
      if (type === 0) {
        this.handlePosterState(1);
        // this.showMessage("生成海报");
        return
      }

      // 员工分享操作
      // else if(this.activityDetails.admin_group_id === 3 && type === 0) {
      //     // this.handleShareLink();
      //     this.showMessage("点击右上角");
      //     return;
      // }

      if (type === 1 && item.self_buygroup_total !== 0) {
        this.showMessage("无法参与，已经是团长了");
        return;
      }
      else if (type === 2 && item.self_buy_total !== 0) {
        this.showMessage("已经参与拼团了");
        return;
      }

      this.isNeedShowSelectGoods = isMultiple;
      this.currentSpellGroupIndex = index;
      this.currentSelectGood = item;
      this.currentOrderType = type;
      this.isShowRegistrationInfo = true;
    },

    /** 更多 参与拼团 */
    handleShowRegistrationInfoJoin(item) {
      const params = {
        id: item.good_id,
        my_buyed: 0,
        my_create_order: 0,
        lastTuan: [item]
      }
      this.isNeedShowSelectGoods = false;
      this.currentSpellGroupIndex = 0;
      this.currentSelectGood = params;
      this.currentOrderType = 2;
      this.isShowRegistrationInfo = true;
    },

    /** 处理选择课程 */
    handleSelcetGood() {
      this.isShowSelctCourse = true;
    },


    /** 进入后台管理页面 */
    goManagementPage() {
      const strUrl = this.activityDetails.admin_group_id === 0 ? "/?id=" + this.id + "&invite_id=" + this.invite_id : "";
      this.$router.push({ path: '/management' + strUrl });
    },


    /** 统一处理弹框关闭 */
    handleClose(done) {
      done();
    },

    /** 显示提示 */
    showMessage(message) {
      this.hintContent = message;
      this.isShowHint = true;
      const timeout = setTimeout(() => {
        this.isShowHint = false;
        clearTimeout(timeout);
      }, 1000);
    },

    /** 去掉字符串左右空格 */
    clearStringSpace(string) {
      string = string ? string : "";
      return string.trimStart().trimEnd();
    },
  }
};
</script>

<style>
@keyframes scroll-y {
  100% {
    transform: translateY(-100%);
  }
}
</style>
<style scoped lang="scss">
@keyframes scroll-x {
  100% {
    transform: translateX(-100%);
  }
}

.refresh-wrap {
  margin-top: .8rem;
  font-size: 1.12rem;
  text-align: center;
  color: #4372fc;
  letter-spacing: .08rem;
  i {
    font-size: 1.28rem;
  }
  span {
    margin-left: .8rem;
  }
}

::v-deep .details-content {
  iframe {
    aspect-ratio: 1.4 / 1;
    width: 100%;
  }
  img {
    width: 100%;
  }
}

.srcoll1 {
  padding: .8rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: .4rem .8rem;
}

.home {
  font-size: 0;
  font-weight: bold;
}

::v-deep .el-dialog {
  border-radius: .4rem;
  .el-dialog__header {
    text-align: center;
  }
  .el-dialog__body {
    padding-top: .8rem;
  }
}

::v-deep .el-drawer {
  border-radius: 1.2rem 1.2rem 0 0;
  height: auto !important;
  header {
    margin-bottom: 0;
    position: absolute;
    right: 0;
    z-index: 1;
  }
}

.course-list .selected {
  border: .08rem solid #ee957f !important;
  background: #f0c8a0 !important;
  color: #f05a11 !important;
}

.join-list {
  max-height: 60vh;
  overflow: auto;
}
.join-item {
  display: flex;
  align-items: center;
  padding: .8rem 0;
}
.join-avatar {
  width: 3.2rem;
  height: 3.2rem;
  overflow: hidden;
  border-radius: 100%;
  background: #f05a11;
  img {
    width: 100%;
    height: 100%;
  }
}
.join-info {
  flex: 1;
  margin: 0 .8rem;

  div:nth-child(2) {
    font-size: .96rem;
  }
  span {
    color: #f05a11;
  }
}
.join-btn {
  padding: 0 .8rem;
  height: 1.92rem;
  line-height: 1.92rem;
  color: #ee957f;
  border-radius: 1.92rem;
  font-size: .96rem;
  border: .08rem solid #ee957f;
}
</style>
